<template>
  <div class="wwa_box main">
    <div class="banner_box">
      <div class="swiper_box">
        <img src="@/assets/images/banner3.jpg" />
      </div>
    </div>
    <div class="container content">
      <ul class="list_">
        <li>
          <div class="logo"><img src="@/assets/images/wwa_logo2.jpg" /></div>

          <div class="con">
            <div class="title_">ECI Awards</div>
            <div class="text_">
              The ECI Awards founded in 2014 was established jointly by Chinese
              scholars and global digital innovators from 27 countries and
              regions activated by an educational institute of philanthropy,
              academic and independent study IECIA. The ECI awards is the first
              universal honor to utilize an evaluation standard to measure “The
              effectiveness of Innovation”. It recognizes those people and
              organizations with excellent achievements in business models,
              products, services, technology, marketing and management, which
              inspired the implementation of commercial and creative industries
              around the world. The ECI Awards has been renowned as the “Nobel
              Prize” for defining the future of the digital economy.
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Introduction',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/whoweare.scss';
</style>
